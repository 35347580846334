<template>
  <a id="pricing" class="anchor invisible relative -top-8"></a>
  <div class="overflow-hidden">
    <div class="relative mx-auto max-w-6xl px-6 py-16 text-center lg:px-8">
      <div
        class="absolute inset-x-0 top-1 -z-10 flex h-full pl-0 blur-2xl sm:top-auto md:top-2/3 md:-translate-y-1/3 lg:bottom-0 lg:right-0"
        aria-hidden="true"
      >
        <div
          class="aspect-[16/9] w-full flex-none border border-black bg-gradient-to-r from-[#80cdfa] to-[#e65454] opacity-25"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>
      <div class="mx-auto max-w-2xl">
        <h2>Simpele prijsopzet</h2>
      </div>
      <div class="relative mt-10">
        <p class="mx-auto max-w-xl text-base">
          Alleen betalen voor wat u nodig heeft, het plannen van een evenement
          is al ingewikkeld genoeg.
        </p>
      </div>
    </div>
    <div data-aos="fade-up" class="pb-24 sm:pb-32">
      <div>
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 md:max-w-4xl md:grid-cols-2">
            <div class="flex flex-col justify-between rounded-lg bg-white p-6 shadow-xl shadow-blue-300/30 ring-1 ring-gray-900/10 sm:px-7">
              <div>
                <div class="flex w-full flex-col flex-wrap justify-between space-y-5 text-center sm:flex-row sm:space-y-0">
                  <h3
                    class="pr-0 text-lg font-semibold uppercase tracking-tighter text-blue-600 sm:pr-3"
                  >
                    Standaard
                  </h3>
                  <div
                    v-if="this.singleStep < this.singleMaxStep"
                    class="relative h-10 whitespace-nowrap text-5xl font-bold tracking-tight text-gray-800"
                    :class="{
                      '!text-3xl': this.singleStep >= this.singleMaxStep,
                    }"
                  >
                    {{ this.singleValue }}
                    <p
                      v-if="this.singleStep < this.singleMaxStep"
                      class="absolute -bottom-7 left-0 right-0 text-sm font-normal tracking-normal text-gray-600 sm:-bottom-8 sm:left-auto"
                    >
                      per evenement (excl. btw)
                    </p>
                  </div>
                  <div
                    v-else
                    class="whitespace-nowrap text-3xl font-bold tracking-tight text-gray-800"
                  >
                    Neem contact op
                  </div>
                </div>

                <div class="p-2">
                  <div class="mt-10">
                    <label for="range-slider" class="text-sm text-gray-600">
                      Aantal vrijwilligers ({{ this.singleStep }}<span v-show="this.singleStep >= this.singleMaxStep">+</span>)
                    </label>
                    <input
                      id="range-slider"
                      v-model="this.singleStep"
                      type="range"
                      min="25"
                      :max="this.singleMaxStep"
                      step="25"
                      class="mt-3 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-300"
                    />
                  </div>

                  <p
                    class="mt-4 leading-6 text-gray-600 sm:mt-8 md:leading-7"
                  >
                    Limieten gelden <span class="font-semibold">per evenement</span> en kunnen altijd later worden verhoogd.
                  </p>
                  <ul
                    role="list"
                    class="mt-5 space-y-1 leading-6 text-gray-600 sm:mt-10 sm:space-y-3"
                  >
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>1</b> evenement aanmaken
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>{{ this.singleStep }}<span v-show="this.singleStep >= this.singleMaxStep">+</span></b> vrijwilligers toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>{{ this.singleCollabAmount(this.singleStep) }}<span v-show="this.singleStep >= this.singleMaxStep">+</span></b> extra beheerder<span v-show="this.yearlyCollabAmount(this.singleStep) > 1">s</span>
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Evenementduur t/m <b>5</b> dagen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig groepen toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig locaties toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig groepen inplannen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Enkelvoudige evenementen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Eigen visuele toevoegingen uit KML, GPX en GeoJSON bestanden
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <Button
                :href="'/login'"
                class="mx-auto mb-1 mt-5 bg-blue-600 hover:scale-105 hover:bg-pink-600 md:mt-8"
                v-show="this.singleStep < this.singleMaxStep"
              >
                Begin
                <ArrowRightIcon
                  class="ml-3 aspect-square h-5"
                  aria-hidden="true"
                />
              </Button>
              <Button
                :href="'mailto:contact@wiewaarwanneer.com'"
                class="mx-auto mb-1 mt-5 bg-blue-600 hover:scale-105 hover:bg-pink-600 md:mt-8"
                v-show="this.singleStep >= this.singleMaxStep"
              >
                Neem contact op
                <EnvelopeIcon
                  class="ml-3 aspect-square h-5"
                  aria-hidden="true"
                />
              </Button>
            </div>
            <div class="flex flex-col justify-between rounded-lg bg-white p-6 shadow-xl shadow-blue-300/30 ring-1 ring-gray-900/10 sm:px-7">
              <div>
                <div class="flex w-full flex-col flex-wrap justify-between space-y-5 text-center sm:flex-row sm:space-y-0">
                  <h3
                    class="pr-0 text-lg font-semibold uppercase tracking-tighter text-blue-600 sm:pr-3"
                  >
                    Jaarlijks
                  </h3>
                  <div
                    v-if="this.yearlyStep < this.yearlyMaxStep"
                    class="relative h-10 whitespace-nowrap text-5xl font-bold tracking-tight text-gray-800"
                    :class="{
                      '!text-3xl': this.yearlyStep >= this.yearlyMaxStep,
                    }"
                  >
                    {{ this.yearlyValue }}
                    <p
                      v-if="this.yearlyStep < this.yearlyMaxStep"
                      class="absolute -bottom-7 left-0 right-0 text-sm font-normal tracking-normal text-gray-600 sm:-bottom-8 sm:left-auto"
                    >
                      per jaar (excl. btw)
                    </p>
                  </div>
                  <div
                    v-else
                    class="whitespace-nowrap text-3xl font-bold tracking-tight text-gray-800"
                  >
                    Neem contact op
                  </div>
                </div>

                <div class="p-2">
                  <div class="mt-10">
                    <label for="range-slider" class="text-sm text-gray-600">
                      Aantal vrijwilligers ({{ this.yearlyStep }}<span v-show="this.yearlyStep >= this.yearlyMaxStep">+</span>)
                    </label>
                    <input
                      id="range-slider"
                      v-model="this.yearlyStep"
                      type="range"
                      min="75"
                      :max="this.yearlyMaxStep"
                      step="25"
                      class="mt-3 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-300"
                    />
                  </div>

                  <p
                    class="mt-4 leading-6 text-gray-600 sm:mt-8 md:leading-7"
                  >
                    Limieten gelden <span class="font-semibold">per organisatie</span> en kunnen altijd later worden verhoogd.
                  </p>
                  <ul
                    role="list"
                    class="mt-5 space-y-1 leading-6 text-gray-600 sm:mt-10 sm:space-y-3"
                  >
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>Oneindig</b> evenementen aanmaken
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>{{ this.yearlyStep }}<span v-show="this.yearlyStep >= this.yearlyMaxStep">+</span></b> vrijwilligers toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        <b>{{ this.yearlyCollabAmount(this.yearlyStep) }}<span v-show="this.yearlyStep >= this.yearlyMaxStep">+</span></b> extra beheerder<span v-show="this.yearlyCollabAmount(this.yearlyStep) > 1">s</span>
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Evenementduur t/m <b>7</b> dagen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig groepen toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig locaties toevoegen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Oneindig groepen inplannen
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Evenementen fragmenteren
                      </span>
                    </li>
                    <li
                      class="flex gap-x-3"
                    >
                      <CheckIcon
                        class="h-6 w-5 flex-none text-pink-600"
                        aria-hidden="true"
                      />
                      <span>
                        Eigen visuele toevoegingen uit KML, GPX en GeoJSON bestanden
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <Button
                :href="'/login'"
                class="mx-auto mb-1 mt-5 bg-blue-600 hover:scale-105 hover:bg-pink-600 md:mt-8"
                v-show="this.yearlyStep < this.yearlyMaxStep"
              >
                Begin
                <ArrowRightIcon
                  class="ml-3 aspect-square h-5"
                  aria-hidden="true"
                />
              </Button>
              <Button
                :href="'mailto:contact@wiewaarwanneer.com'"
                class="mx-auto mb-1 mt-5 bg-blue-600 hover:scale-105 hover:bg-pink-600 md:mt-8"
                v-show="this.yearlyStep >= this.yearlyMaxStep"
              >
                Neem contact op
                <EnvelopeIcon
                  class="ml-3 aspect-square h-5"
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 md:max-w-4xl mt-8">
            <div class="flex flex-col justify-between rounded-lg bg-white p-6 shadow-xl shadow-blue-300/30 ring-1 ring-gray-900/10 sm:px-7">
              <div>
                <div class="flex w-full flex-col flex-wrap justify-between space-y-5 text-center sm:flex-row sm:space-y-0">
                  <h3 class="pr-0 text-lg font-semibold uppercase tracking-tighter text-blue-600 sm:pr-3">
                    Enterprise
                  </h3>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div>
                    <p class="mt-2 pr-4 leading-6 text-gray-600 md:leading-7">
                      Heeft u meer nodig of zoekt u een oplossing op maat? Neem contact met ons op en wij helpen u graag verder.
                    </p>
                  </div>
                  <div>
                    <Button
                      :href="'/support'"
                      class="mx-auto flex mb-1 mt-5 bg-blue-600 hover:scale-105 hover:bg-pink-600 md:mt-8"
                    >
                      Neem contact op
                      <EnvelopeIcon
                        class="ml-3 aspect-square h-5"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer with negative margin -->
  </div>
</template>

<script>
import Button from "@/Components/Button.vue";
import {
  ArrowRightIcon,
  CheckIcon,
  EnvelopeIcon,
} from "@heroicons/vue/24/outline";
import { usePage } from "@inertiajs/vue3";

export default {
  data() {
    return {
      singleStep: 75,
      singleMaxStep: 300,
      yearlyStep: 75,
      yearlyMaxStep: 250,
    };
  },
  computed: {
    singlePriceExists() {
      return this.singleStep in this.pricing.single;
    },
    singleValue() {
      if(!(this.singlePriceExists)) return "Neem contact op";
      return "€" + (this.pricing.single[this.singleStep] / 100);
    },
    yearlyPriceExists() {
      return this.yearlyStep in this.pricing.yearly;
    },
    yearlyValue() {
      if(!(this.yearlyPriceExists)) return "Neem contact op";
      return "€" + (this.pricing.yearly[this.yearlyStep] / 100);
    },
  },
  methods: {
    singleCollabAmount(people) {
      return Math.ceil(people / 100);
    },
    yearlyCollabAmount(people) {
      return Math.ceil(people / 99);
    },
  },
  setup() {
    const pricing = usePage().props.pricing;
    console.log(pricing);
    return { pricing };
  },
  components: {
    Button,
    ArrowRightIcon,
    CheckIcon,
    EnvelopeIcon,
  }
}
</script>
