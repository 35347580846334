<script setup>
import { onMounted, onUnmounted, ref } from "vue"

const props = defineProps(["features"])

const selectedFeature = ref(0)
let intervalDesktop

// Auto-plays the features
function handleInterval() {
  if (selectedFeature.value == props.features.length - 1) {
    selectedFeature.value = 0
  } else {
    selectedFeature.value++
  }
}

// Go to specific feature and stop the auto-play
function handleClick(index) {
  clearInterval(intervalDesktop)
  selectedFeature.value = index
}

onMounted(() => {
  intervalDesktop = setInterval(handleInterval, 5000)
})

onUnmounted(() => clearInterval(intervalDesktop))
</script>

<template>
  <!-- Desktop Features bar -->
  <div
    class="featuresBar mt-10 flex w-full overflow-hidden px-3 shadow-blue-900 lg:justify-between lg:rounded-lg lg:bg-white lg:shadow-lg"
  >
    <button
      v-for="(item, index) in features"
      @click="handleClick(index)"
      data-aos="fade-up"
      :data-aos-delay="(1 + index) * 50"
      data-aos-once="false"
      class="featureBtn group relative mx-1 block cursor-pointer items-center overflow-visible rounded-lg bg-white px-3 py-5 lg:m-0 lg:bg-transparent"
      :class="{ 'after:w-full': index == selectedFeature }"
      role="button"
      :aria-label="`Go to ${item.shortName}`"
    >
      <div
        class="flex items-center justify-center rounded-lg transition-all lg:mb-3"
        :class="[index == selectedFeature ? 'text-pink-600' : 'text-blue-500']"
      >
        <component
          :is="item.icon"
          class="mr-2 aspect-square h-5 transition group-hover:scale-110 lg:mr-0 lg:h-6"
          :class="{ 'scale-110': index == selectedFeature }"
          aria-hidden="true"
        />
      </div>
      <span
        class="whitespace-nowrap text-sm font-medium uppercase"
        :class="{ 'font-semibold': item.selected }"
      >
        {{ item.shortName }}
      </span>
    </button>
  </div>
  <!-- Features block -->
  <div
    class="mt-5 flex w-full justify-between overflow-hidden rounded-lg bg-white p-4 shadow-lg shadow-blue-900 transition-all"
  >
    <div data-aos="fade-in" class="flex w-[72%] pr-5">
      <img
        class="rounded-md border object-contain p-1 outline-gray-300 ring-1 ring-gray-300/50 transition"
        :src="asset(features[selectedFeature].image)"
        alt=""
      />
    </div>
    <div data-aos="fade-left" :key="selectedFeature" class="w-[28%] p-2">
      <div class="flex items-center space-x-3">
        <div>
          <component
            :is="features[selectedFeature].icon"
            class="mr-1 h-8 w-8 text-pink-600 transition"
            aria-hidden="true"
          />
        </div>
        <h3 class="sm:text- font-bold uppercase">
          {{ features[selectedFeature].name }}
        </h3>
      </div>
      <p class="mt-4 min-h-[70px] leading-5 text-gray-600 sm:leading-6 lg:mt-6">
        {{ features[selectedFeature].description }}
      </p>
    </div>
  </div>
</template>
